import { graphql, useStaticQuery, Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const Hero = () => {
  const { heroImage } = useStaticQuery(graphql`
    query {
      heroImage: file(sourceInstanceName: { eq: "hero" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <div className="relative grid">
      <div>
        <GatsbyImage image={heroImage.childImageSharp.gatsbyImageData} alt="" />
      </div>
      <div className="absolute place-self-center">
        <div className="text-center text-white">
          <div className="flex flex-col place-items-center pb-8 md:pb-24">
            <h2 className="pb-4 md:pb-8 text-xl md:text-4xl">Looking forward to working with you!</h2>
            <Link to="/contact">
              <button className="border transition duration-300 hover:bg-white hover:bg-opacity-20 uppercase mx-auto px-8 py-2 rounded">
                Contact Me
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
